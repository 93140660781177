<template>
    <div class="column" :style="{ marginTop: top + 'px', width: width }">
        <div class="label">
            {{ label }}
        </div>
        <div class="value">{{ value }}</div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: "",
        },
        value: {
            type: String | Number,
            default: "",
        },
        width: {
            type: String,
            default: "33.3%",
        },
        top: {
            type: Number,
            default: 0,
        },
    },
};
</script>
<style scoped>
.column {
    display: flex;
    width: 33.3%;
    font-size: 13px;
    line-height: 22px;
}
.label {
    width: 110px;
    color: #999;
    text-align: right;
}
.value {
    flex: 1;
    margin-left: 10px;
}
</style>
