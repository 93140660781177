<template>
    <div class="ticket">
        <el-card style="height: calc(100vh - 160px)">
            <div slot="header" class="header">消费记录</div>
            <div class="toolBar">
                <h4 style="padding: 0 10px">消费日期</h4>
                <el-date-picker
                    v-model="date"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                ></el-date-picker>
                <h4 style="padding: 0 10px">账户类别</h4>
                <el-select placeholder="请选择账户类别" v-model="accountType">
                    <el-option
                        v-for="item in accountTypeSelect"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
                <h4 style="padding: 0 10px">变动方式</h4>
                <el-select placeholder="请选择变动方式" v-model="way">
                    <el-option
                        v-for="item in waySelect"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
                <el-button
                    class="red"
                    @click="handleQueryTable()"
                    style="margin: 0 10px; height: 40px"
                >
                    查询
                </el-button>
            </div>
            <el-table
                v-loading="tableLoading"
                :data="tableData"
                height="calc(86vh - 250px)"
                style="width: calc(100% - 40px)"
            >
                <el-table-column
                    prop="orderNo"
                    label="订单编号"
                    align="center"
                    width="190"
                ></el-table-column>
                <el-table-column
                    prop="amount"
                    label="变动金额(火币)"
                    align="center"
                >
                    <template slot-scope="scope">
                        <span
                            :style="{
                                color:
                                    scope.row.amount < 0
                                        ? 'red'
                                        : scope.row.amount > 0
                                        ? 'green'
                                        : '#333',
                            }"
                        >
                            {{ scope.row.amount / payRate }}
                        </span>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="way" label="变动原因" align="center">
                    <template slot-scope="scope">
                        {{
                            changeWay[scope.row.way] &&
                            changeWay[scope.row.way][1]
                                ? changeWay[scope.row.way][1]
                                : "-"
                        }}
                    </template>
                </el-table-column> -->
                <el-table-column prop="balance" label="账户余额" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.balance / payRate }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="nbFreeBalance"
                    label="体验余额"
                    align="center"
                >
                    <template slot-scope="scope">
                        {{
                            scope.row.origin === "NB"
                                ? scope.row.nbFreeBalance / payRate
                                : scope.row.origin === "SH"
                                ? scope.row.freeBalance / payRate
                                : "-"
                        }}
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="origin" label="消费口岸" align="center">
                    <template slot-scope="scope">
                        {{
                            scope.row.origin === "NB"
                                ? "宁波"
                                : scope.row.origin === "SH"
                                ? "上海"
                                : "-"
                        }}
                    </template>
                </el-table-column> -->
                <!-- <el-table-column
                    prop="category"
                    label="消费类目"
                    align="center"
                >
                    <template slot-scope="scope">
                        {{ changeRoute[scope.row.category] || "-" }}
                    </template>
                </el-table-column> -->
                <el-table-column
                    prop="remarks"
                    label="订单备注"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="updateTime"
                    label="消费时间"
                    align="center"
                    width="250"
                >
                    <template slot-scope="scope">
                        {{ scope.row.updateTime }}
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="totalNum"
                    v-if="totalNum > 0"
                ></el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>
import { queryAccountRecord } from "@/api/pay";
import Moment from "moment";
import PayMentDetailItem from "./PayMentDetailItem.vue";
import { payRate } from "@/utils/pay-data-list";
export default {
    components: { PayMentDetailItem },
    data() {
        return {
            pickerOptions: {
                shortcuts: [
                    {
                        text: "最近一周",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 7,
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近一个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 30,
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近三个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 90,
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                ],
            },
            date: "",
            table: [],

            type: 1,
            companyName: "",
            tableData: [],
            rules: {},
            pageNo: 1,
            pageSize: 20,
            totalNum: 0,
            tableLoading: false,
            changeWay: {
                RECHARGE: ["+", "充值"],
                GIVE: ["+", "赠送"],
                CONSUME: ["-", "消费"],
                REFUND: ["+", "退款"],
                FREE_GIVE: ["+", "免费赠送"],
                FREE_CONSUME: ["-", "免费消费"],
            },
            changeRoute: {
                0: "提单号",
                1: "船期",
            },
            accountType: null,
            accountTypeSelect: [
                {
                    value: 0,
                    label: "个人账户",
                },
                {
                    value: 1,
                    label: "企业账户",
                },
                {
                    value: null,
                    label: "全部",
                },
            ],
            way: null,
            waySelect: [
                {
                    value: "RECHARGE",
                    label: "充值",
                },
                {
                    value: "GIVE",
                    label: "赠送",
                },
                {
                    value: "CONSUME",
                    label: "消费",
                },
                {
                    value: "REFUND",
                    label: "退款",
                },
                {
                    value: "FREE_GIVE",
                    label: "免费赠送",
                },
                {
                    value: "FREE_CONSUME",
                    label: "免费消费",
                },
                {
                    value: null,
                    label: "全部",
                },
            ],
            payRate,
        };
    },
    computed: {},
    methods: {
        payStatusCheck(item) {
            return this.payStatus[item];
        },
        // 获取默认时间
        defaultTime() {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            this.date = [start, end];
        },
        // 根据类型筛选
        filterToValue(ele) {
            return ele.map((item, index) => ({
                text: item.name,
                value: index,
            }));
        },
        // 筛选
        filterHandler(value, row, column) {
            const property = column["property"];
            return row[property] === value;
        },
        openTicketInfoDialog() {
            if (this.lastForm.companyName) {
                this.ticketDialogVisible = true;
                return;
            }
            getCompanyInvoiceByCompanyId().then(({ data }) => {
                this.form = data.data;
                this.lastForm = data.data;
                this.ticketDialogVisible = true;
            });
        },
        handleLookDetail(id) {
            getCompanyInvoiceRecordDetails({ id }).then(({ data }) => {
                this.ticketLookDialogVisible = true;
            });
        },
        handleCurrentChange(val) {
            this.pageNo = val;
            this.handleQueryTable();
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.handleQueryTable();
        },
        handleQueryTable() {
            const distanceDate = Moment(this.date[1]) - Moment(this.date[0]);
            const perDayTime = 3600 * 1000 * 24;
            if (distanceDate / perDayTime > 90) {
                this.$message.error("最大时间间隔不能超过90天");
                return;
            }
            const param = {
                accountType: this.accountType,
                way: this.way,
                startTime:
                    Moment(this.date[0]).format("yyyy-MM-DD") + " 00:00:00",
                endTime:
                    Moment(this.date[1]).format("yyyy-MM-DD") + " 23:59:59",
                pageSize: this.pageSize,
                pageNo: this.pageNo,
            };
            this.tableLoading = true;
            queryAccountRecord(param)
                .then(({ data }) => {
                    this.tableData = data.data.list;
                    this.totalNum = data.data.total;
                })
                .finally(() => {
                    this.tableLoading = false;
                });
        },
    },
    mounted() {
        this.defaultTime();
        this.handleQueryTable();
    },
};
</script>
<style scoped lang="stylus">
/deep/ .el-card__header {
    background-image: linear-gradient(
        var(--RED-C11C20),
        var(--RED-79031D),
        var(--RED-68020F)
    );
}
.detailHeader {
    line-height: 36px;
    font-weight: 600;
    font-size: 16px;
    padding-left: 20px;
    background: #f2f2f2;
    border-left: 4px solid var(--RED-C11C20);
}
.red {
    background-color: var(--RED-C11C20);
    color: white;
}
.header {
    color: white;
    font-size: 18px;
    font-weight: 700;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    margin: 10px 20px 100px 0px;
}

.ticket{
    min-width:800px;
}

.toolBar {
    display: flex;
}
</style>
<!-- 改造 -->
<!-- <template>
    <div class="ticket">
        <el-card style="height: calc(100vh - 160px)">
            <div slot="header" class="header">消费记录</div>
            <BaseTable
                :loading="tableLoading"
                :tableData="tableData"
                :tableFilter="consumeTableFilter"
                :tableColumnOptions="consumeTableList"
                @tableUpdate="handleQueryTable"
                :height="'calc(86vh - 250px)'"
                :tableDataTotal="totalNum"
                :hasBorder="false"
                :searchBarShow="true"
                :tableStyle="'width: calc(100% - 40px);'"
            ></BaseTable>
            <el-table
                v-loading="tableLoading"
                :data="tableData"
                height="calc(86vh - 250px)"
                style="width: calc(100% - 40px)"
            >
                <el-table-column
                    prop="orderNo"
                    label="订单编号"
                    align="center"
                    width="190"
                ></el-table-column>
                <el-table-column
                    prop="amount"
                    label="变动金额(火币)"
                    align="center"
                >
                    <template slot-scope="scope">
                        <span
                            :style="{
                                color:
                                    changeWay[scope.row.way][0] === '-'
                                        ? 'red'
                                        : 'green',
                            }"
                        >
                            {{ changeWay[scope.row.way][0] + scope.row.amount }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="way" label="变动原因" align="center">
                    <template slot-scope="scope">
                        {{ changeWay[scope.row.way][1] }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="balance"
                    label="账户余额"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="nbFreeBalance"
                    label="账户余额(免)"
                    align="center"
                ></el-table-column>
                <el-table-column prop="origin" label="变动渠道" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.origin === "NB" ? "宁波" : "上海" }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="category"
                    label="消费类目"
                    align="center"
                >
                    <template slot-scope="scope">
                        {{ changeRoute[scope.row.category] }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="remarks"
                    label="订单备注"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="updateTime"
                    label="变动时间"
                    align="center"
                    width="250"
                >
                    <template slot-scope="scope">
                        {{ scope.row.updateTime }}
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
import { queryAccountRecord } from "@/api/pay";
import { consumeTableList, consumeTableFilter } from "@/utils/pay-data-list";
import BaseTable from "@/components/common/BaseTable.vue";
import Moment from "moment";
import PayMentDetailItem from "./PayMentDetailItem.vue";
export default {
    components: { PayMentDetailItem, BaseTable },
    data() {
        return {
            pickerOptions: {
                shortcuts: [
                    {
                        text: "最近一周",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 7,
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近一个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 30,
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近三个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 90,
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                ],
            },
            date: "",
            table: [],

            type: 1,
            companyName: "",
            tableData: [],
            rules: {},
            pageNo: 1,
            pageSize: 20,
            totalNum: 0,
            tableLoading: false,
            changeWay: {
                RECHARGE: ["+", "充值"],
                GIVE: ["+", "赠送"],
                CONSUME: ["-", "消费"],
                REFUND: ["+", "退款"],
                FREE_GIVE: ["+", "免费赠送"],
                FREE_CONSUME: ["-", "免费消费"],
            },
            changeRoute: {
                0: "提单号",
                1: "船期",
            },
            accountType: null,
            accountTypeSelect: [
                {
                    value: 0,
                    label: "个人账户",
                },
                {
                    value: 1,
                    label: "企业账户",
                },
                {
                    value: null,
                    label: "全部",
                },
            ],
            way: null,
            consumeTableList: consumeTableList,
            consumeTableFilter: consumeTableFilter,
            waySelect: [
                {
                    value: "RECHARGE",
                    label: "充值",
                },
                {
                    value: "GIVE",
                    label: "赠送",
                },
                {
                    value: "CONSUME",
                    label: "消费",
                },
                {
                    value: "REFUND",
                    label: "退款",
                },
                {
                    value: "FREE_GIVE",
                    label: "免费赠送",
                },
                {
                    value: "FREE_CONSUME",
                    label: "免费消费",
                },
                {
                    value: null,
                    label: "全部",
                },
            ],
        };
    },
    computed: {},
    methods: {
        payStatusCheck(item) {
            return this.payStatus[item];
        },

        handleQueryTable(params) {
            console.log(params);
            this.tableLoading = true;
            queryAccountRecord(params)
                .then(({ data }) => {
                    this.tableData = data.data?.list || [];
                    this.totalNum = data.data?.total || 0;
                })
                .finally(() => {
                    this.tableLoading = false;
                });
        },
    },
};
</script>
<style scoped lang="stylus">
/deep/ .el-card__header {
    background-image: linear-gradient(
        var(--RED-C11C20),
        var(--RED-79031D),
        var(--RED-68020F)
    );
}
.detailHeader {
    line-height: 36px;
    font-weight: 600;
    font-size: 16px;
    padding-left: 20px;
    background: #f2f2f2;
    border-left: 4px solid var(--RED-C11C20);
}
.red {
    background-color: var(--RED-C11C20);
    color: white;
}
.header {
    color: white;
    font-size: 18px;
    font-weight: 700;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    margin: 10px 20px 100px 0px;
}

.ticket{
    min-width:800px;
}

.toolBar {
    display: flex;
}
</style> -->
